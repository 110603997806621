// dev
const dev = {
  baseUrl: "http://localhost:5000/api",
};

const prod = {
  baseUrl: "https://isakhan-backend.herokuapp.com/api",
};

const config = process.env.REACT_APP_ENV === "dev" ? dev : prod;

export default {
  endpoints: {
    user: {
      login: "/user/login",
      register: "/user/register",
      getMe: "/user/me",
      updateName: "/user/updatename",
    },
    print: {
      getAll: "/print/all",
      getPrint: "/print",
    },
  },
  ...config,
};
