import { PRINTS_FETCHED, PRINTS_FETCHING } from "../actions/types";

export default function (
  state = {
    loading: false,
    prints: [],
  },
  action
) {
  switch (action.type) {
    case PRINTS_FETCHING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PRINTS_FETCHED: {
      return {
        ...state,
        loading: false,
        prints: action.prints,
      };
    }
    default: {
      return state;
    }
  }
}
