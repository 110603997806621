import http from "./index";
import config from "../config";

export const getAllPrints = async () => {
  try {
    const res = await http.get(config.endpoints.print.getAll);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPrint = async (printId) => {
  try {
    const res = await http.post(config.endpoints.print.getPrint, { printId });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
