import {
    LOGIN_FAIL,
    LOGIN_START,
    LOGIN_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP_START,
    SIGNUP_SUCCESS,
    USER_FETCHING,
    USER_FETCHED,
    LOGOUT,
    CLEAR_ERROR,
    CLEAR_SUCCESS,
    MAIN_LOADING_START,
    MAIN_LOADING_STOP,
    PROFILE_UPDATING,
    PROFILE_UPDATED
} from './types'
import API from '../http'
import config from '../config'

export function loadUser() {
    return async function(dispatch) {
        dispatch({
            type: USER_FETCHING
        })
        dispatch({
            type: MAIN_LOADING_START
        })
        try {
            const res = await API.get(config.endpoints.user.getMe);
            const {user} = res.data;
            console.log(res.data);
            dispatch({
                type: USER_FETCHED,
                user
            })
            dispatch({
                type: MAIN_LOADING_STOP
            })
        } catch (error) {
            dispatch({
                type: LOGOUT
            })
            dispatch({
                type: MAIN_LOADING_STOP
            })
            throw error;
        }
    }
}

export function loginFail(error) {
    return {
        type: LOGIN_FAIL,
        error
    }
}

export function loginSuccess(user) {
    return {
        type: LOGIN_SUCCESS,
        user
    }
}

export function signUpStart() {
    return {
        type: SIGNUP_START
    }
}

export function signupSuccess(message) {
    return {
        type: SIGNUP_SUCCESS,
        message
    }
}

export function signupFail(error) {
    return {
        type: SIGNUP_FAIL,
        error
    }
}

export function logout() {
    return {
        type: LOGOUT
    }
}

export function clearError() {
    return {
        type: CLEAR_ERROR
    }
}

export function clearSuccess() {
    return {
        type: CLEAR_SUCCESS
    }
}

export function profileUpdating() {
    return {
        type: PROFILE_UPDATING
    }
}

export function profileUpdated(profile) {
    return {
        type: PROFILE_UPDATED,
        profile
    }
}