import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP_SUCCESS,
    USER_FETCHED,
    USER_FETCHING,
    LOGIN_START,
    SIGNUP_START,
    LOGOUT,
    CLEAR_ERROR,
    CLEAR_SUCCESS,
    PROFILE_UPDATED,
    PROFILE_UPDATING
} from '../actions/types'

export default function(state={
    isLoggedIn: JSON.parse(localStorage.getItem('token')) !== null,
    user: JSON.parse(localStorage.getItem('user')),
    loading: false,
    error: null,
    message: null
}, action) {

    switch(action.type) {
        case LOGIN_SUCCESS: {
            return {
                ...state,
                isLoggedIn: true,
                user: action.user,
                loading: false
            }
        }
        case LOGIN_FAIL: {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                loading: false,
                error: action.error
            }
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                loading: false,
                message: action.message
            }
        }
        case SIGNUP_FAIL: {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                loading: false,
                error: action.error
            }
        }
        case SIGNUP_START: {
            return {
                ...state,
                loading: true
            }
        }
        case LOGIN_START: {
            return {
                ...state,
                loading: true
            }
        }
        case LOGOUT: {
            localStorage.clear()
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                user: null
            }
        }
        case USER_FETCHING: {
            return {
                ...state,
                loading: true
            }
        }
        case USER_FETCHED: {
            localStorage.setItem('user', JSON.stringify(action.user))
            return {
                ...state,
                loading: false,
                user: action.user
            }
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null
            }
        }
        case CLEAR_SUCCESS: {
            return {
                ...state,
                message: null
            }
        }
        case PROFILE_UPDATED: {
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    firstname: action.profile.firstname,
                    lastname: action.profile.lastname
                }
            }
        }
        case PROFILE_UPDATING: {
            return {
                ...state
            }
        }
        default: {
            return state
        }
    }

}