import React from "react";
import Classes from "./PrintCard.module.css";
import { withRouter } from "react-router-dom";

const PrintCard = ({ print, history }) => {
  const goToPrint = () => {
    history.push(`prints/${print._id}`);
  };

  const cover = print.images[0].thumb;

  return (
    <div className={Classes.main} onClick={goToPrint}>
      <div className={Classes.overlay}></div>
      <div className={Classes.view + " logo-font"}>Click to view</div>
      <img src={cover} alt={print.name} />
    </div>
  );
};

export default withRouter(PrintCard);
