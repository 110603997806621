import React, { useEffect } from "react";
import Classes from "./Prints.module.css";
import PrintCard from "../../shared/components/PrintCard/PrintCard";
import { getAllPrints } from "../../http/print";
import { connect } from "react-redux";
import { fetchingPrints, printsFetched } from "../../actions/print";

import MasonryLayout from "../../shared/components/MasonryLayout/MasonryLayout";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Prints = (props) => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    props.dispatch(fetchingPrints());
    getAllPrints()
      .then((res) => res.prints)
      .then((prints) => {
        props.dispatch(printsFetched(prints));
      })
      .catch((err) => {
        console.log(err);
        props.dispatch(printsFetched([]));
      });
  }, []);

  return (
    <div className={Classes.main}>
      <div className={Classes.printCards}>
        {props.prints && (
          <MasonryLayout
            columns={width <= 480 ? 1 : width <= 768 ? 2 : 3}
            gap={10}
          >
            {props.prints.map((print) => (
              <PrintCard print={print} key={print._id} />
            ))}
          </MasonryLayout>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.print.loading,
    prints: state.print.prints,
  };
};

export default connect(mapStateToProps)(Prints);
