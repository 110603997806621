import React, { Component, useEffect } from "react";
import Classes from "./Calendar.module.css";
import { Carousel } from 'react-responsive-carousel';

const Calendar = () => {
    
    useEffect(() => {
        const forms = document.querySelectorAll(".rzp");
        for(let i = 0; i < forms.length; i++) {
            const form = forms[i];
            if (!form.hasChildNodes()) {

                const script = document.createElement("script");
                script.src = "https://checkout.razorpay.com/v1/payment-button.js";
                script.async = true;
                script.dataset.payment_button_id = "pl_NHmIm6LKxmH09q";
                form.appendChild(script);
          
              }
        }
    }, [])

    return <div className={Classes.main}>
    <form className="rzp" style={{
        margin: "12px 0"
    }}></form>
        <div>
        <Carousel infiniteLoop={true}>
    <div>
        <img src="assets/IMG_8556.JPG" />
    </div>
    <div>
        <img src="assets/IMG_8557.JPG" />
    </div>
    <div>
        <img src="assets/IMG_8558.JPG" />
    </div>
    <div>
        <img src="assets/IMG_8559.JPG" />
    </div>
    <div>
        <img src="assets/IMG_8563.JPG" />
    </div>
    <div>
        <img src="assets/IMG_8564.JPG" />
    </div>
</Carousel>
        </div>
        <div style={{
            fontSize: "16px",
            marginBottom: "24px"
        }}>
        This calendar reflects my love for photography in a colourful way. It showcases six years of my passion, with each month unveiling a snapshot of my journeys. It is a visual odyssey capturing the essence of diverse destinations in India and around the world. Join me in celebrating the beauty of travel, one month at a time, as we embark on a year-long adventure together.
        </div>
        <div className={Classes.productDetails}>
            <div className={Classes.title}>Product description</div>
            <div className={Classes.keyValue}>
                <div className={Classes.key}>Box size - </div>
                <div className={Classes.value}>7.5x11.5x2 inches</div>
            </div>
            <div className={Classes.keyValue}>
                <div className={Classes.key}>Box material - </div>
                <div className={Classes.value}>Pure pinewood box</div>
            </div>
            <div className={Classes.keyValue}>
                <div className={Classes.key}>Stand base material - </div>
                <div className={Classes.value}>High gloss painted wooden block</div>
            </div>
            <div className={Classes.keyValue}>
                <div className={Classes.key}>Stand hanger - </div>
                <div className={Classes.value}>Copper tube</div>
            </div>
            <div className={Classes.last}>All prints are printed on 300 GSM gloss paper with varnish coating.</div>
        </div>
        </div>
}

export default Calendar;
