import { combineReducers } from "redux";

import main from "./main";
import user from "./user";
import navbar from "./navbar";
import profile from "./profile";
import print from "./print";

export default combineReducers({
  main,
  user,
  navbar,
  profile,
  print,
});
