import React, { Component } from 'react'
import Classes from './Login.module.css'
import {Link, Redirect} from 'react-router-dom'
import {loginFail, loginSuccess, clearError, clearSuccess} from '../../actions/user'
import {connect} from 'react-redux'
import {login} from '../../http/user'
import { loadingStart, loadingStop } from '../../actions/main'

export class Login extends Component {

    constructor(props) {
        super();
        this.state = {
            email: '',
            password: ''
        }
        this.onLogin = this.onLogin.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    render() {
        return (
            <div className={Classes.main}>
                {
                    this.props.isLoggedIn ? <Redirect to="/" /> : null
                }
                <div className={Classes.formMain}>
                    {
                        this.props.error ? <div className={Classes.error}>
                                            {this.props.error}
                                        </div> : null
                    }
                    <div className={Classes.formContainer}>
                        <div className={Classes.title}>
                            Login to the shop
                        </div>
                        <form>
                            <input onChange={this.handleEmail} type="text" placeholder="Email Address" name="email" />
                            <input onChange={this.handlePassword} type="password" placeholder="Password" name="password" />
                            <div className={Classes.forgot}>
                                <Link to="/forgotpassword">Forgot Password?</Link>
                            </div>
                            <div className={`${Classes.btn} ${Classes.login}`}>
                                <button onClick={this.onLogin}>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        if(this.props.isLoggedIn) {
            this.props.history.push('/');
        }
        this.props.dispatch(clearError())
        this.props.dispatch(clearSuccess())
    }

    handleEmail(e) {
        let email = e.target.value
        this.setState(prevState => ({
            email: email
        }))
    }

    handlePassword(e) {
        let password = e.target.value
        this.setState(prevState => ({
            password: password
        }))
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async onLogin(e) {
        e.preventDefault();
        this.props.dispatch(clearError())
        this.props.dispatch(clearSuccess())
        if(!this.state.email || !this.validateEmail(this.state.email)) {
            this.props.dispatch(loginFail('Invalid email address'))
            return;
        }
        if(!this.state.password) {
            this.props.dispatch(loginFail('Please enter a password'))
            return;
        }
        this.props.dispatch(loadingStart())
        const {success, user, token, error} = await login(this.state.email, this.state.password);
        if(success) {
            localStorage.setItem('token', JSON.stringify(token))
            this.props.dispatch(loginSuccess(user))
        } else {
            this.props.dispatch(loginFail(error));
        }
        this.props.dispatch(loadingStop())
    }
}

const mapStateToProps = state => {
    return {
        error: state.user.error,
        isLoggedIn: state.user.isLoggedIn
    }
}

export default connect(mapStateToProps)(Login)