import {
    PROFILE_ERROR,
    PROFILE_SUCCESS
} from './types'

export function profileError(error) {
    return {
        type: PROFILE_ERROR,
        error
    }
}

export function profileSuccess(message) {
    return {
        type: PROFILE_SUCCESS,
        message
    }
}