import { gql } from "@apollo/client";

export const getWorkshopsQuery = gql`
  query getWorkshops($filters: WorkshopFiltersInput) {
    workshops(filters: $filters) {
      id
      title
      description
      dates {
        startDate
        endDate
      }
      totalSeats
      bookedSeats
      meetingUrl
      cardImageUrl
      bannerImageUrl
    }
  }
`;

export const getWorkshopQuery = gql`
  query getWorkshop($id: ID!) {
    workshop(id: $id) {
      id
      title
      description
      dates {
        startDate
        endDate
      }
      totalSeats
      bookedSeats
      meetingUrl
      cardImageUrl
      bannerImageUrl
    }
  }
`;
