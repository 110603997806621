import React, { Component } from 'react'
import Classes from './Signup.module.css'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {signupFail, signupSuccess, clearError, clearSuccess, signUpStart} from '../../actions/user'
import {loadingStart, loadingStop} from '../../actions/main'
import {register} from '../../http/user'

export class Signup extends Component {

    constructor(props) {
        super();
        this.state = {
            email: '',
            password: '',
            firstname: '',
            lastname: ''
        }
        this.onSignup = this.onSignup.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleFirstname = this.handleFirstname.bind(this);
        this.handleLastname = this.handleLastname.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    render() {
        return (
            <div className={Classes.main}>
                {
                    this.props.isLoggedIn ? <Redirect to="/" /> : null
                }
                <div className={Classes.formMain}>
                    {
                        this.props.error ? <div className={Classes.error}>
                                            {this.props.error}
                                        </div> : null
                    }
                    {
                        this.props.message ? <div className={Classes.message}>
                                            {this.props.message}
                                        </div> : null
                    }
                    <div className={Classes.formContainer}>
                        <div className={Classes.title}>
                            Signup to the shop
                        </div>
                        <form>
                            <input value={this.state.email} onChange={this.handleEmail} type="text" placeholder="Email Address" name="email" />
                            <input value={this.state.firstname} onChange={this.handleFirstname} type="text" placeholder="Firstname" name="firstname" />
                            <input value={this.state.lastname} onChange={this.handleLastname} type="text" placeholder="Lastname" name="lastname" />
                            <input value={this.state.password} onChange={this.handlePassword} type="password" placeholder="Password" name="password" />
                            <div className={`${Classes.btn} ${Classes.login}`}>
                                <button onClick={this.onSignup}>Signup</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        if(this.props.isLoggedIn) {
            this.props.history.push('/');
        }
        this.props.dispatch(clearError())
        this.props.dispatch(clearSuccess())
    }

    handleEmail(e) {
        let email = e.target.value
        this.setState(prevState => ({
            email: email
        }))
    }

    handlePassword(e) {
        let password = e.target.value
        this.setState(prevState => ({
            password: password
        }))
    }

    handleFirstname(e) {
        let firstname = e.target.value
        this.setState(prevState => ({
            firstname: firstname
        }))
    }

    handleLastname(e) {
        let lastname = e.target.value
        this.setState(prevState => ({
            lastname: lastname
        }))
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async onSignup(e) {
        e.preventDefault();
        this.props.dispatch(clearError())
        this.props.dispatch(clearSuccess())
        if(!this.state.email || !this.validateEmail(this.state.email)) {
            this.props.dispatch(signupFail('Invalid email address'))
            return;
        }
        if(!this.state.firstname || !this.state.lastname) {
            this.props.dispatch(signupFail('Firstname or Lastname cannot be empty'))
            return;
        }
        if(!this.state.password) {
            this.props.dispatch(signupFail('Please enter a password'))
            return;
        }
        this.props.dispatch(signUpStart());
        this.props.dispatch(loadingStart());
        const {success, message, error} = await register({
            email: this.state.email,
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname
        });
        if(success) {
            this.props.dispatch(signupSuccess(message))
        } else {
            this.props.dispatch(signupFail(error))
        }
        this.props.dispatch(loadingStop())
    }

}

const mapStateToProps = state => {
    return {
        error: state.user.error,
        message: state.user.message,
        isLoggedIn: state.user.isLoggedIn
    }
}

export default connect(mapStateToProps)(Signup)