import React, { Component } from 'react'
import Classes from './Banner.module.css'

export class Banner extends Component {
    render() {
        return (
            <div className={Classes.main}>
                <div className={Classes.content+' logo-font'}>
                    <h1>Isa Khan ki dukaan!</h1>
                    <h3>Sabse sasta, sabse maddha.</h3>
                </div>
            </div>
        )
    }
}

export default Banner