import http from './index'
import config from '../config'

export const login = async function(email, password) {
    try {
        const res = await http.post(config.endpoints.user.login, {email, password});
        return res.data;
    } catch (error) {
        return error.response.data
    }
}

export const register = async function(data) {
    try {
        const res = await http.post(config.endpoints.user.register, data);
        return res.data;
    } catch (error) {
        return error.response.data
    }
}

export const loadUser = async function() {
    try {
        const res = await http.get(config.endpoints.user.getMe);
        return res.data;
    } catch (error) {
        return error.response.data
    }
}

export const updateName = async function(firstname, lastname) {
    try {
        const res = await http.post(config.endpoints.user.updateName, {firstname, lastname});
        return res.data;
    } catch (error) {
        return error.response.data
    }
}