import React, { Component } from "react";
import Classes from "./Navbar.module.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../../actions/user";
import { openUserMenu, closeUserMenu } from "../../../actions/navbar";
import UserMenu from "../UserMenu/UserMenu";

export class Navbar extends Component {
  constructor(props) {
    super();
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.userMenuRef = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  render() {
    return (
      <div className={Classes.main}>
        <div className={Classes.wrapper}>
          <div className={Classes.logo}>
            <Link to="/">Travel Calendar - Isa Khan</Link>
          </div>
          <div className={Classes.menu}>
            <ul>
              <li>{/* <Link to="/prints">Prints</Link> */}</li>
              {!this.props.isLoggedIn ? (
                <>
                  <li>{/* <Link to="/login">Login</Link> */}</li>
                  <li>{/* <Link to="/signup">SignUp</Link> */}</li>
                </>
              ) : null}
              {this.props.isLoggedIn ? (
                <>
                  <li>
                    {/* <button
                    ref={this.userMenuRef}
                    onClick={this.toggleUserMenu}
                    className={Classes.accountBtn}
                  >
                    <i className="far fa-user-circle"></i>
                    {this.props.userMenu ? (
                      <div className={Classes.userMenu}>
                        <UserMenu
                          onClose={() => this.props.dispatch(closeUserMenu())}
                          onLogout={() => this.props.dispatch(logout())}
                        />
                      </div>
                    ) : null}
                  </button> */}
                  </li>
                  {/* <li><button onClick={() => this.props.dispatch(logout())} >Logout</button></li> */}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick(e) {
    if (
      this.userMenuRef.current &&
      !this.userMenuRef.current.contains(e.target)
    ) {
      if (this.props.userMenu) {
        this.props.dispatch(closeUserMenu());
      }
    }
  }

  toggleUserMenu() {
    if (this.props.userMenu) {
      this.props.dispatch(closeUserMenu());
    } else {
      this.props.dispatch(openUserMenu());
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userMenu: state.navbar.userMenu,
  };
};

export default connect(mapStateToProps)(Navbar);
