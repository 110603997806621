import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

let client;

export const getApolloClient = () => {
  if (!client) {
    client = new ApolloClient({
      cache: new InMemoryCache(),
      link: createUploadLink({
        uri: "https://isakhan-backend.herokuapp.com/graphql",
      }),
    });
  }
  return client;
};
