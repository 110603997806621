export const CHANGE_PAGE_SUFFIX = "CHANGE_PAGE_SUFFIX";

// main app action types
export const MAIN_LOADING_START = "MAIN_LOADING_START";
export const MAIN_LOADING_STOP = "MAIN_LOADING_STOP";

// user action types
export const USER_FETCHING = "USER_FETCHING";
export const USER_FETCHED = "USER_FETCHED";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

// navbar menu actions types
export const USER_MENU_OPEN = "USER_MENU_OPEN";
export const USER_MENU_CLOSE = "USER_MENU_CLOSE";

export const PROFILE_UPDATING = "PROFILE_UPDATING";
export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";

export const PRINTS_FETCHING = "PRINTS_FETCHING";
export const PRINTS_FETCHED = "PRINTS_FETCHED";
