import {
    USER_MENU_CLOSE,
    USER_MENU_OPEN
} from '../actions/types'

export default function(state={
    userMenu: false
}, action) {

    switch(action.type) {
        case USER_MENU_OPEN: {
            return {
                ...state,
                userMenu: true
            }
        }
        case USER_MENU_CLOSE: {
            return {
                ...state,
                userMenu: false
            }
        }
        default: {
            return state
        }
    }

}