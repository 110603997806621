import React, { Component } from 'react'
import {connect} from 'react-redux';
import Classes from './Home.module.css'
import Banner from '../../shared/components/Banner/Banner'
import Featured from '../../shared/components/Featured/Featured';

export class Home extends Component {

    constructor(props) {
        super();
    }

    render() {
        return (
            <div className={Classes.main}>
                <Banner />
                <Featured />
            </div>
        )
    }

    componentDidMount() {
    }

}

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);