import { PRINTS_FETCHED, PRINTS_FETCHING } from "./types";

export function fetchingPrints() {
  return {
    type: PRINTS_FETCHING,
  };
}

export function printsFetched(prints) {
  return {
    type: PRINTS_FETCHED,
    prints,
  };
}
