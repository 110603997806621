import React, { Component } from 'react'
import Classes from './Featured.module.css'

export class Featured extends Component {
    render() {
        return (
            <div className={Classes.main}>
                <div className={Classes.title}>Featured</div>
                <div className={Classes.imgsContainer}>
                    <div className={Classes.imgContainer}>
                        <div className={Classes.img} style={{backgroundImage: 'url(https://source.unsplash.com/random/800x600)'}}></div>
                        <div className={Classes.view+' logo-font'}>Click to view</div>
                        <div className={Classes.overlay}></div>
                    </div>
                    <div className={Classes.imgContainer}>
                        <div className={Classes.img} style={{backgroundImage: 'url(https://source.unsplash.com/random/800x600)'}}></div>
                        <div className={Classes.view+' logo-font'}>Click to view</div>
                        <div className={Classes.overlay}></div>
                    </div>
                    <div className={Classes.imgContainer}>
                        <div className={Classes.img} style={{backgroundImage: 'url(https://source.unsplash.com/random/800x600)'}}></div>
                        <div className={Classes.view+' logo-font'}>Click to view</div>
                        <div className={Classes.overlay}></div>
                    </div>
                    <div className={Classes.imgContainer}>
                        <div className={Classes.img} style={{backgroundImage: 'url(https://source.unsplash.com/random/800x600)'}}></div>
                        <div className={Classes.view+' logo-font'}>Click to view</div>
                        <div className={Classes.overlay}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Featured