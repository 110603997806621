import React, { Component } from 'react'
import Classes from './MyProfile.module.css'
import {connect} from 'react-redux'
import {profileError, profileSuccess} from '../../actions/profile'
import {loadingStart, loadingStop} from '../../actions/main'
import {updateName} from '../../http/user'
import { profileUpdated } from '../../actions/user'

export class MyProfile extends Component {

    constructor(props) {
        super()
        this.state = {
            firstname: '',
            lastname: ''
        }
        this.handleFirstname = this.handleFirstname.bind(this)
        this.handleLastname = this.handleLastname.bind(this)
        this.updateName = this.updateName.bind(this)
    }

    render() {
        return (
            <div className={Classes.main}>
                {
                    this.props.error ? <div className={Classes.error}>{this.props.error}</div> : null
                }
                {
                    this.props.message ? <div className={Classes.message}>{this.props.message}</div> : null
                }
                <div className={Classes.title}>
                    My Profile
                </div>
                {
                    this.props.user ?
                    <div className={Classes.form}>
                        <input value={this.state.firstname} onChange={this.handleFirstname} type="text" placeholder="Firstname" name="firstname" />
                        <input value={this.state.lastname} onChange={this.handleLastname} type="text" placeholder="Lastname" name="lastname" />
                        <button onClick={this.updateName}>Update</button>
                    </div> :
                    null
                }
            </div>
        )
    }

    componentDidMount() {
        this.setState(prevState => ({
            firstname: this.props.user.firstname,
            lastname: this.props.user.lastname
        }))
    }

    handleFirstname(e) {
        let firstname = e.target.value;
        this.setState(prevState => ({
            firstname: firstname
        }))
    }

    handleLastname(e) {
        let lastname = e.target.value;
        this.setState(prevState => ({
            lastname: lastname
        }))
    }

    async updateName() {
        if(this.state.firstname === this.props.user.firstname && this.state.lastname === this.props.user.lastname) {
            console.log('equal')
            return;
        }
        if(this.state.firstname !== this.props.user.firstname || this.state.lastname !== this.props.user.lastname) {
            console.log('not equal')
            if(!this.state.firstname) {
                this.props.dispatch(profileError('Firstname cannot be empty!'))
                return;
            }
            if(!this.state.lastname) {
                this.props.dispatch(profileError('Lastname cannot be empty!'))
                return
            }
        }
        this.props.dispatch(loadingStart())
        const {success, error, message, profile} = await updateName(this.state.firstname, this.state.lastname);
        if(success) {
            this.props.dispatch(profileUpdated(profile))
            this.props.dispatch(profileSuccess(message))
        } else {
            this.props.dispatch(profileError(error))
        }
        this.props.dispatch(loadingStop())
    }

}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        error: state.profile.error,
        message: state.profile.message
    }
}

export default connect(mapStateToProps)(MyProfile)