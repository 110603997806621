import React, { Component } from "react";
import Classes from "./EmptyCart.module.css";
import EmptyCartImage from "../../../assets/empty-cart.svg";
import { Link } from "react-router-dom";

export class EmptyCart extends Component {
  render() {
    return (
      <div className={Classes.main}>
        <div className={Classes.wrapper}>
          <img src={EmptyCartImage} />
          <div className={Classes.emptyText}>Uh-oh, your cart is empty</div>
          <div className={Classes.shoppingBtn}>
            <Link to="/prints">Start Shopping</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default EmptyCart;
