import {
    MAIN_LOADING_START,
    MAIN_LOADING_STOP
} from '../actions/types'

export default function(state={
    loading: false
}, action) {

    switch(action.type) {
        case MAIN_LOADING_START: {
            return {
                ...state,
                loading: true
            }
        }
        case MAIN_LOADING_STOP: {
            return {
                ...state,
                loading: false
            }
        }
        default: {
            return state
        }
    }

}