import React, { useEffect } from "react";
import Classes from "./Footer.module.css";
import { Link, withRouter } from "react-router-dom";

const Footer = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setTimeout(() => {
        if (action === "POP") {
          return;
        }
        window.scrollTo(0, 0);
      });
    });
    return () => {
      unlisten();
    };
  });
  return (
    <div className={Classes.main}>
      <div className={Classes.logo + " logo-font"}>Isa Khan</div>
      {/* <div className={Classes.links}>
        <div className={Classes.link}>
          <Link to="/">Home</Link>
        </div>
        <div className={Classes.link}>
          <Link to="/collections">Collections</Link>
        </div>
        <div className={Classes.link}>
          <Link to="/prints">Prints</Link>
        </div>
        <div className={Classes.link}>
          <Link to="/contact">Contact</Link>
        </div>
      </div> */}
    </div>
  );
};

export default withRouter(Footer);
