import React, { Component } from "react";
import Classes from "./UserMenu.module.css";
import { Link } from "react-router-dom";

export class UserMenu extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className={Classes.main}>
        <div className={Classes.arrow}></div>
        <div className={Classes.mask}></div>
        <ul>
          <li>
            <Link to="/cart">Cart</Link>
          </li>
          <li>
            <Link to="/profile">My Profile</Link>
          </li>
          <li>
            <Link to="/orders">My Orders</Link>
          </li>
          <li>
            <Link to="/addressbook">Address Book</Link>
          </li>
          <li>
            <button onClick={this.props.onLogout}>Logout</button>
          </li>
        </ul>
      </div>
    );
  }
}

export default UserMenu;
