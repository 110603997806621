import React, { Component } from "react";
import Classes from "./Cart.module.css";
import EmptyCart from "../../shared/components/EmptyCart/EmptyCart";

export class Cart extends Component {
  render() {
    return (
      <div className={Classes.main}>
        <EmptyCart />
      </div>
    );
  }
}

export default Cart;
