import {
    PROFILE_ERROR,
    PROFILE_SUCCESS
} from '../actions/types'

export default function(state={
    error: null,
    message: null
}, action) {

    switch(action.type) {
        case PROFILE_ERROR: {
            return {
                ...state,
                error: action.error
            }
        }
        case PROFILE_SUCCESS: {
            return {
                ...state,
                message: action.message
            }
        }
        default: {
            return state
        }
    }

}