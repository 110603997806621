import React from "react";
import Classes from "./MyGallery.module.css";

import ImageGallery from "react-image-gallery";

export default function MyGallery({ images, width }) {
  const imagesArray = images.map((image) => {
    return {
      original: image.thumb,
      thumbnail: image.thumb,
      fullscreen: image.full,
    };
  });
  const properties = {
    lazyLoad: true,
    thumbnailPosition: width <= 480 ? "bottom" : "left",
    useBrowserFullscreen: false,
    showPlayButton: false,
  };
  return <ImageGallery {...properties} items={imagesArray} />;
}
