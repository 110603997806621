import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import "./App.css";
import { loadUser } from "./actions/user";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Home from "./pages/Home/Home";
import Prints from "./pages/Prints/Prints";
import PrintDetail from "./pages/PrintDetail/PrintDetail";
import Navbar from "./shared/components/Navbar/Navbar";
import Footer from "./shared/components/Footer/Footer";
import Spinner from "./shared/components/Spinner/Spinner";
import MyProfile from "./pages/MyProfile/MyProfile";
import PrivateRoute from "./shared/components/PrivateRoute/PrivateRoute";
import Cart from "./pages/Cart/Cart";
import Events from "./pages/Events/Events";
import Workshops from "./pages/Workshops";
import WorkshopDetails from "./pages/WorkshopDetails";
import Calendar from "./pages/Calendar";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"

function App(props) {
  useEffect(() => {
    // props.dispatch(loadUser());
  }, []);
  return (
    <div className="main">
      {props.loading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : null}
      <div className="wrapper">
        <Router>
          <div className="navbar">
            <Navbar />
          </div>
          <div className="content">
            <Switch>
              {/* Home wuz here!!! */}
              {/* <Route path="/login" exact component={Login} />
            <Route path="/workshop" exact component={Events} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/prints" exact component={Prints} />
            <Route path="/prints/:id" exact component={PrintDetail} />
            <PrivateRoute path="/profile" component={MyProfile} />
            <PrivateRoute path="/cart" component={Cart} /> */}
              {/* <Route
                path="/workshops/:workshopId"
                exact
                component={WorkshopDetails}
              />
              <Route path="/workshops" exact component={Workshops} />*/}
              <Route path="/calendar" component={Calendar} />
              <Route path="*" component={() => <Redirect to="/calendar" />} /> 
            </Switch>
          </div>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.main.loading,
  };
};

export default connect(mapStateToProps)(App);
