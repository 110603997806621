import React, { useEffect, useState } from "react";
import Classes from "./PrintDetail.module.css";
import { connect } from "react-redux";
import { getPrint } from "../../http/print";

import MyGallery from "../../shared/components/MyGallery/MyGallery";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const PrintDetail = ({ match, prints }) => {
  const [print, setPrint] = useState();
  const { width } = useWindowDimensions();
  useEffect(() => {
    const { id: printId } = match.params;
    const print = getPrintFromState(printId);
    if (print) {
      setPrint(print);
    } else {
      getPrint(printId)
        .then((res) => res.print)
        .then((print) => {
          setPrint(print);
          console.log(print);
        })
        .catch((err) => {
          console.log(err);
          setPrint(null);
        });
    }
  }, []);

  const getPrintFromState = (printId) => {
    return prints.filter((print) => print._id === printId)[0];
  };

  const onSizeSelect = (e) => {
    const index = e.target.options[e.target.selectedIndex].value;
    const size = print.availableSize[index];
    console.log(size);
  };

  return (
    <div className={Classes.main}>
      {print && (
        <div className={Classes.wrapper}>
          <div className={Classes.galleryContainer}>
            <MyGallery images={print.images} width={width} />
          </div>
          <div className={Classes.right}>
            <div className={`${Classes.printTitle} font-heading`}>
              {print.name}
            </div>
            <div className={`${Classes.price}`}>₹ {print.price}</div>
            <div className={`${Classes.description}`}>{print.description}</div>
            <div className={Classes.sizeSelectContainer}>
              <label htmlFor="sizes" className={"font-heading"}>
                Size:
              </label>
              <br />
              <select name="sizes" id="sizes" onChange={onSizeSelect}>
                {print.availableSize.map((size, index) => {
                  return (
                    <option value={index} key={index}>
                      {size.width} x {size.height} {size.unit}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={Classes.btnsContainer}>
              <button>Add to cart</button>
              <button>Buy it now</button>
            </div>
            <hr />
            <div className={Classes.shareContainer}>
              <div className={Classes.text}>Share:</div>
              <a
                className={Classes.icon}
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${print.name}&url=${window.location}&via=isa_khan10101`}
                rel="noopener noreferrer"
              >
                <i class="fab fa-twitter"></i>
              </a>
              <a
                className={Classes.icon}
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}
                rel="noopener noreferrer"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              {width <= 480 && (
                <a
                  className={Classes.icon}
                  target="_blank"
                  href={`whatsapp://send?text=${encodeURIComponent(
                    window.location.href
                  )}`}
                  data-action="share/whatsapp/share"
                  rel="noopener noreferrer"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.print.loading,
    prints: state.print.prints,
  };
};

export default connect(mapStateToProps)(PrintDetail);
