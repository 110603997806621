import {
    MAIN_LOADING_START,
    MAIN_LOADING_STOP
} from './types'

export function loadingStart() {
    return {
        type: MAIN_LOADING_START
    }
}

export function loadingStop() {
    return {
        type: MAIN_LOADING_STOP
    }
}