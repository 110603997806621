import {
    USER_MENU_CLOSE,
    USER_MENU_OPEN
} from './types'

export function openUserMenu() {
    return {
        type: USER_MENU_OPEN
    }
}

export function closeUserMenu() {
    return {
        type: USER_MENU_CLOSE
    }
}